@media (max-width: 500px) {
  .image-height3 {
    height: 530px;
  }
}

@media (min-width: 500px) {
  .image-height3 {
    height: 773px;
    width: 580px;
  }
}

@media (max-width: 500px) {
  .padding3 {
    padding-right: 25px !important;
  }
}
