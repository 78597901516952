@media (max-width: 500px) {
  .image-height {
    height: 500px;
  }
}

@media (min-width: 500px) {
  .image-height {
    height: 790px;
    width: 571px;
  }
}

@media (max-width: 500px) {
  .image-height1 {
    height: 300px;
  }
}

@media (min-width: 500px) {
  .image-height1 {
    height: 486px;
    width: 592px;
  }
}

@media (max-width: 500px) {
  .padding1 {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }
}

@media (max-width: 500px) {
  .padding2 {
    padding-right: 25px !important;
    padding-left: 25px !important;
  }
}
