@media (max-width: 500px) {
  .image-height2 {
    height: 300px;
  }
}

@media (min-width: 500px) {
  .image-height2 {
    height: 486px;
    width: 592px;
  }
}
