@media (max-width: 500px) {
  .heading1 {
    font-size: 25px;
  }
}

@media (min-width: 500px) {
  .heading1 {
    font-size: 70px;
    line-height: 105px;
  }
}

@media (max-width: 500px) {
  .heading2 {
    font-size: 15px;
  }
}

@media (min-width: 500px) {
  .heading2 {
    font-size: 40px;
    line-height: 59px;
  }
}

.carousel-indicators .active {
  background-color: #85c71f;
}

.carousel-indicators [data-bs-target] {
  opacity: 1;
}
