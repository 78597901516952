/* Contactus.css */

/* Centered alert container */
.alert-container {
  position: fixed;
  top:50%;
  left: 50%;
  margin-top: 7px ;
  transform: translate(-50%,-50%);
  z-index: 1050; /* Ensure it is above other elements */
}





.contact-title {
    font-family: "Poppins", sans-serif;
    color: #000000;
    font-weight: 600;
    font-size: 25px;
    line-height: 37px;
  }
  
  .contact-description {
    font-family: "Poppins", sans-serif;
    color: #898989;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    max-width: 352px;
  }
  
  .contact-detail {
    font-family: "Poppins", sans-serif;
    color: #898989;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    max-width: 352px;
  }
  
  .form-group label {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #898989;
  }
  
  .form-control {
    border: none;
    border-radius: 0;
    border-bottom: 2px solid #A9A9A9;
    box-shadow: none;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    color: #333333;
  }
  
  .form-control::placeholder {
    font-family: "Poppins", sans-serif;
    color: #cccccc;
  }
  
  .btn-submit {
    background-color: #8bc34a;
    color: white;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 16px;
    padding: 10px 20px;
    border: none;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  

  
  